<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="vidhansabhalist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="maq1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Vidhan Sabha</h5>
                        </template>

                        <template v-slot:right>
                            <Button label="Import" icon="pi pi-paperclip" class="p-mr-2" @click="onImportVidhansabha" />
                            <Button label="Add Vidhan Sabha" icon="pi pi-plus" class="p-mr-2"
                                @click="addVidhanSabhaDialogOpen" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash"
                                class="p-button-success" @click="filterlisting()"></Button>
                        </template>
                    </Toolbar>

                    <template #empty> No data found. </template>

                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Vidhan Sabha Name" headerStyle="width: 33%">

                        <template #body="{ data }">
                            <div>
                                <div class="p-text-capitalize"><span v-tooltip.right="'Vidhan Sabha'">{{ data.maq5 ?
                    data.maq5 : 'N/A'
                                        }} <span v-if="data.maq6">({{ data.maq6 }})</span></span></div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Loksabha" headerStyle="width: 15%">

                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.maq4 ? data.maq4 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="State" headerStyle="width: 15%">

                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.maq8 ? data.maq8 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="District" headerStyle="width: 14%">

                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.maq12 ? data.maq12 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Constituency" headerStyle="width: 15%">

                        <template #body="{ data }">
                            <span v-if="data.maq16 == 1" :class="'user-badge status-temp-suspend'">Urban</span>
                            <span v-if="data.maq16 == 2" :class="'user-badge status-temp-suspend'">Rural</span>
                            <span v-if="data.maq16 == 3" :class="'user-badge status-temp-suspend'">Urban & Rural</span>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">

                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editVidhanSabhaDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add vidhansabha dialog start here -->
    <Dialog v-model:visible="addVidhanSabhaStatus" :style="{ width: '800px' }" header="Add Vidhan Sabha" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" @change="onStateSelect"
                        optionLabel="label" placeholder="Select ..." autofocus :filter="true" appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="district">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="district" v-model="district" :options="districtList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">
                        Lok Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="loksabhaname" v-model="loksabhaname" :options="loksabhaList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.loksabhaname.$error">{{
                    v$.loksabhaname.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabhaname">
                        Vidhan Sabha Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="vidhansabhaname" class="p-text-capitalize" v-model.trim="vidhansabhaname"
                        required="true" maxlength="50" :class="{ 'p-invalid': submitted && !vidhansabhaname }" />
                    <small class="p-invalid p-error" v-if="v$.vidhansabhaname.$error">{{
                    v$.vidhansabhaname.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabhanumber">
                        Vidhan Sabha Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="vidhansabhanumber" class="p-text-capitalize" v-model.trim="vidhansabhanumber"
                        required="true" @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !vidhansabhanumber }" />
                    <small class="p-invalid p-error" v-if="v$.vidhansabhanumber.$error">{{
                    v$.vidhansabhanumber.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                        v$.reservationvalue.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Constitency Type <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="urban_type" name="option" v-bind:value="1" v-model="constitencytype"
                            :checked="constitencytype == 1" />
                        <label for="urban_type" class="p-mr-2">Urban</label>
                        <RadioButton id="rural_type" name="option" v-bind:value="2" v-model="constitencytype"
                            :checked="constitencytype == 2" />
                        <label for="rural_type" class="p-mr-2">Rural</label>
                        <RadioButton id="both_type" name="option" v-bind:value="3" v-model="constitencytype"
                            :checked="constitencytype == 3" />
                        <label for="both_type" class="p-mr-2">Both</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.constitencytype.$error">{{
                    v$.constitencytype.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="active_type" name="option" v-bind:value="1" v-model="statustype"
                            :checked="statustype == 1" />
                        <label for="active_type" class="p-mr-2">Active</label>
                        <RadioButton id="inactive_type" name="option" v-bind:value="0" v-model="statustype"
                            :checked="statustype == 0" />
                        <label for="inactive_type" class="p-mr-2">Inactive</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.statustype.$error">{{
                    v$.statustype.$errors[0].$message
                }}</small>
                </div>
            </div>
        </div>

        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addVidhanSabha" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add vidhansabha dialog end here -->
    <!-- edit vidhansabha dialog start here -->
    <Dialog v-model:visible="editVidhanSabhaStatus" :style="{ width: '800px' }" header="Edit Vidhan Sabha" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" @change="onStateSelect($event)"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="district">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="district" v-model="district" :options="districtList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">
                        Lok Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="loksabhaname" v-model="loksabhaname" :options="loksabhaList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.loksabhaname.$error">{{
                    v$.loksabhaname.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabhaname">
                        Vidhan Sabha Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="vidhansabhaname" class="p-text-capitalize" v-model.trim="vidhansabhaname"
                        required="true" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !vidhansabhaname }" />
                    <small class="p-invalid p-error" v-if="v$.vidhansabhaname.$error">{{
                    v$.vidhansabhaname.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabhanumber">
                        Vidhan Sabha Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="vidhansabhanumber" class="p-text-capitalize" v-model.trim="vidhansabhanumber"
                        required="true" @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !vidhansabhanumber }" />
                    <small class="p-invalid p-error" v-if="v$.vidhansabhanumber.$error">{{
                    v$.vidhansabhanumber.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                        v$.reservationvalue.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Constituency Type <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="urban_type" name="option" v-bind:value="1" v-model="constitencytype"
                            :checked="constitencytype == 1" />
                        <label for="urban_type" class="p-mr-2">Urban</label>
                        <RadioButton id="rural_type" name="option" v-bind:value="2" v-model="constitencytype"
                            :checked="constitencytype == 2" />
                        <label for="rural_type" class="p-mr-2">Rural</label>
                        <RadioButton id="both_type" name="option" v-bind:value="3" v-model="constitencytype"
                            :checked="constitencytype == 3" />
                        <label for="both_type" class="p-mr-2">Both</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.constitencytype.$error">{{
                    v$.constitencytype.$errors[0].$message
                }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="active_type" name="option" v-bind:value="1" v-model="statustype"
                            :checked="statustype == 1" />
                        <label for="active_type" class="p-mr-2">Active</label>
                        <RadioButton id="inactive_type" name="option" v-bind:value="0" v-model="statustype"
                            :checked="statustype == 0" />
                        <label for="inactive_type" class="p-mr-2">Inactive</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.statustype.$error">{{
                    v$.statustype.$errors[0].$message
                }}</small>
                </div>
            </div>
        </div>

        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateVidhanSabha" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit vidhansabha dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '800px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">State</label>
                    <MultiSelect v-model="state_fks" :options="stateList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">District</label>
                    <MultiSelect v-model="district_fks" :options="districtList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">Lok Sabha</label>
                    <MultiSelect v-model="loksabha_fks" :options="loksabhaList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">Vidhan Sabha</label>
                    <MultiSelect v-model="vidhansabha_fks" :options="vidhansabhaList" optionValue="value"
                        optionLabel="label" placeholder="Select" :filter="true" class="multiselect-custom"
                        appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Constituency Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="urban_type" name="option" v-bind:value="1" v-model="constitencytype_fk" />
                        <label for="urban_type" class="p-mr-2">Urban</label>
                        <RadioButton id="rural_type" name="option" v-bind:value="2" v-model="constitencytype_fk" />
                        <label for="rural_type" class="p-mr-2">Rural</label>
                        <RadioButton id="both_type" name="option" v-bind:value="3" v-model="constitencytype_fk" />
                        <label for="both_type" class="p-mr-2">Both</label>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(state_fks, loksabha_fks, district_fks, vidhansabha_fks, constitencytype_fk)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportVidhansabha" :style="{ width: '1100px' }" header="Import Vidhan Sabha"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" ref="importVidhansabhaFile" class="custom-upload-btn-ultima"
                        v-model="importVidhansabhaFile" v-on:change="handleUploadImport()" />
                    <div style="color:red" v-if="importmsg.file">
                        {{ importmsg.file }}
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                    <label class="p-mr-2">
                        Download Sample Sheet:
                    </label>
                    <!-- TODO: Need CSV File URL here -->
                    <router-link href="https://storage.googleapis.com/meraneta_admin/import_vidhansabha_data.csv"
                        target="_blank" to="#">
                        <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                        </Button>
                    </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_vidhansabha_data.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload
                            it.
                        </li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee,
                            else
                            enquiry will be inserted into logged-in employees Lok-neta account.</li>
                        <li>Project name, unit type & source must be the same as in the Lok-neta System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source
                            column's are mandatory.</li>
                        <li>Submitted sheets for importing vidhansabha data till 10 pm will be executed on the same
                            night
                            and the
                            result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>

        <template #footer>
            <Button v-if="!importfileloader" label="Submit" icon="pi pi-check" class="p-button-text" :disabled="importmsg.file != '' ||
                    importVidhansabhaFile == null ||
                    importVidhansabhaFile == ''
                " @click="onCSVSubmit" style="width: 90px" />
            <Button v-show="importfileloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            vidhansabhalist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addVidhanSabhaStatus: false,
            editVidhanSabhaStatus: false,
            state: '',
            stateList: [],
            district: '',
            districtList: [],
            loksabhaname: '',
            loksabhaList: [],
            vidhansabhaname: '',
            vidhansabhanumber: '',
            reservationvalue: '',
            reservationList: [],
            constitencytype: '',
            showLoader: false,
            submitted: false,
            row_id: null,
            statustype: 1,
            filterListingDialog: false,
            state_fks: [],
            loksabha_fks: [],
            district_fks: [],
            vidhansabha_fks: [],
            vidhansabhaList: [],
            constitencytype_fk: [],
            showFillFilter: false,
            isImportVidhansabha: false,
            importfileloader: false,
            importmsg: [],
            importVidhansabhaFile: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getvidhansabhalist();
        this.getReservations();
        // this.getloksabha();
        this.getStates();
        // this.getdistrict();
    },
    validations() {
        return {
            state: { required: helpers.withMessage('Please select state', required) },
            district: { required: helpers.withMessage('Please select district', required) },
            loksabhaname: { required: helpers.withMessage('Please select lok sabha', required) },
            vidhansabhaname: { required: helpers.withMessage('Please enter Vidhan sabha name', required) },
            vidhansabhanumber: { required: helpers.withMessage('Please enter vidhansabha number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric) },
            //   reservationvalue: { required: helpers.withMessage('Please select reservation', required) },
            constitencytype: { required: helpers.withMessage('Please select constituency type', required) },
            statustype: { required: helpers.withMessage('Please select status', required) },
        };
    },
    methods: {
        onImportVidhansabha() {
            this.isImportVidhansabha = true;
        },
        handleUploadImport() {
            var file = this.$refs.importVidhansabhaFile.files[0];
            let allowedExtensions = /(\.csv)$/i;
            if (!file) {
                this.importmsg["file"] = "Please upload CSV excel file.";
            } else if (!file.size > 2048) {
                this.importmsg["file"] = "File size cannot exceed more than 2MB";
            } else if (!file.name.match(allowedExtensions)) {
                this.importmsg["file"] = "Invalid file type";
            } else {
                this.importmsg["file"] = "";
                this.importVidhansabhaFile = this.$refs.importVidhansabhaFile.files[0];
            }
        },
        onCSVSubmit() {
            this.importfileloader = true;
            var formData = new FormData();
            formData.append("importfile", this.importVidhansabhaFile);
            this.ApiService.importVidhansabhaData(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.isImportVidhansabha = false;
                    // this.getloksabhalist();
                    this.importfileloader = false;
                    this.importVidhansabhaFile = "";
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },
        onStateSelect(e) {
            this.getdistrictName(e);
            this.getlokhsabhaName(e);
        },
        getdistrictName(e) {
            this.loksabhaname = '';
            this.vidhansabhaname = '';
            this.vidhansabhanumber = '';
            this.constitencytype = '';
            if (e.value) {
                this.ApiService.getdistrict({ state_id: e.value.value }).then((items) => {
                    if (items.success === true) {
                        this.districtList = items.data;
                    }
                });
            }
        },
        getlokhsabhaName(e) {
            this.vidhansabhaname = '';
            this.vidhansabhanumber = '';
            this.constitencytype = '';
            if (e.value) {
                this.ApiService.getloksabha({ mbb4: e.value.value }).then((items) => {
                    if (items.success === true) {
                        this.loksabhaList = items.data;
                    }
                });
            }
        },
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        // getStateID(e) {
        //     this.ApiService.getdistrict({ mbb4 : this.em }).then((data) => {
        //         if (data.status == 200) {
        //             this.districtList = data.data;
        //         } else {
        //             this.districtList = '';
        //         }
        //     });
        // },
        getReservations(ev) {
            this.ApiService.getReservations(ev).then((data) => {
                if (data.status == 200) {
                    this.reservationList = data.data;
                } else {
                    this.reservationList = '';
                }
            });
        },
        // getloksabha(ev) {
        //     this.ApiService.getloksabha(ev).then((data) => {
        //         if (data.status == 200) {
        //             this.loksabhaList = data.data;
        //         } else {
        //             this.loksabhaList = '';
        //         }
        //     });
        // },
        getvidhansabhalist() {
            this.loading = true;
            this.ApiService.getvidhansabhalist({
                page_no: this.page_no,
                statefilter: this.state_fks,
                loksabhafilter: this.loksabha_fks,
                districtfilter: this.district_fks,
                vidhansabhafilter: this.vidhansabha_fks,
                constitenctfilter: this.constitencytype_fk
            }).then((data) => {
                if (data.status == 200) {
                    this.vidhansabhalist = data.data.result;
                    this.totalRecords = data.data.count;
                } else {
                    this.vidhansabhalist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getvidhansabhalist();
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addVidhanSabhaDialogOpen() {
            this.addVidhanSabhaStatus = true;
            this.submitted = false;
            this.state = '';
            this.district = '';
            this.loksabhaname = '';
            this.vidhansabhaname = '';
            this.vidhansabhanumber = '';
            this.reservationvalue = '';
            this.constitencytype = '';
            this.statustype = 1;
        },
        editVidhanSabhaDialogOpen(e) {
            this.getvidhansabhalist();
            this.editVidhanSabhaStatus = true;
            this.submitted = false;
            this.row_id = e.maq1;
            let maq7 = this.stateList.filter(function (item) {
                return item.value == e.maq7;
            });
            if (maq7.length > 0) {
                this.state = maq7[0];
            }
            this.ApiService.getdistrict({ state_id: e.maq7 }).then((items) => {
                if (items.success === true) {
                    this.districtList = items.data;
                    let maq11 = this.districtList.filter(function (item) {
                        return item.mbb4 == e.maq11;
                    });
                    if (maq11.length > 0) {
                        this.district = maq11[0];
                    }
                }
            });

            this.ApiService.getloksabha({ mbb4: e.maq7 }).then((items) => {
                if (items.success === true) {
                    this.loksabhaList = items.data;
                    let maq3 = this.loksabhaList.filter(function (item) {
                        return item.value == e.maq3;
                    });
                    if (maq3.length > 0) {
                        this.loksabhaname = maq3[0];
                    }
                }
            });

            this.vidhansabhaname = e.maq5;
            this.vidhansabhanumber = e.maq6;
            let maq13 = this.reservationList.filter(function (item) {
                return item.value == e.maq13;
            });
            if (maq13.length > 0) {
                this.reservationvalue = maq13[0];
            }
            this.statustype = e.maq15;
            this.constitencytype = e.maq16;
        },
        addVidhanSabha() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['maq7'] = this.state.value;
            fields['maq8'] = this.state.label;
            fields['maq11'] = this.district.value;
            fields['maq12'] = this.district.label;
            fields['maq3'] = this.loksabhaname.value;
            fields['maq4'] = this.loksabhaname.label;
            fields['maq5'] = this.vidhansabhaname;
            fields['maq6'] = this.vidhansabhanumber;
            fields['maq13'] = this.reservationvalue.value;
            fields['maq14'] = this.reservationvalue.label;
            fields['maq15'] = this.statustype;
            fields['maq16'] = this.constitencytype;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createvidhansabha(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addVidhanSabhaStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.state = '';
                        this.district = '';
                        this.loksabhaname = '';
                        this.vidhansabhaname = '';
                        this.vidhansabhanumber = '';
                        this.reservationvalue = '';
                        this.constitencytype = '';
                        this.statustype = 1;
                        this.getvidhansabhalist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        updateVidhanSabha() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['maq1'] = this.row_id;
            fields['maq7'] = this.state.value;
            fields['maq8'] = this.state.label;
            fields['maq11'] = this.district.value;
            fields['maq12'] = this.district.label;
            fields['maq3'] = this.loksabhaname.value;
            fields['maq4'] = this.loksabhaname.label;
            fields['maq5'] = this.vidhansabhaname;
            fields['maq6'] = this.vidhansabhanumber;
            fields['maq13'] = this.reservationvalue.value;
            fields['maq14'] = this.reservationvalue.label;
            fields['maq15'] = this.statustype;
            fields['maq16'] = this.constitencytype;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createvidhansabha(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.editVidhanSabhaStatus = false;
                        this.showLoader = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getvidhansabhalist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        filterlisting() {
            this.filterListingDialog = true;
            this.getVidhansabhaFilter();
            this.getStatesFilter();
            this.getDistrictFilter();
            this.getLokhsabhaFilter();
        },
        getFilteredData(event_state, event_loksabha, event_district, event_vidhansabha, event_constitency) {
            this.page_no = 0;
            this.loading = true;
            this.state_fks = event_state;
            this.loksabha_fks = event_loksabha;
            this.district_fks = event_district;
            this.vidhansabha_fks = event_vidhansabha;
            this.constitencytype_fk = event_constitency;
            this.getvidhansabhalist({
                statefilter: this.state_fks,
                loksabhafilter: this.loksabha_fks,
                districtfilter: this.district_fks,
                vidhansabhafilter: this.vidhansabha_fks,
                constitenctfilter: this.constitencytype_fk
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.state_fks = "";
            this.loksabha_fks = '';
            this.district_fks = '';
            this.vidhansabha_fks = '';
            this.constitencytype_fk = '';
            this.getvidhansabhalist();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },
        getVidhansabhaFilter(ev) {
            this.ApiService.getvidhansabha(ev).then((data) => {
                if (data.status == 200) {
                    this.vidhansabhaList = data.data;
                } else {
                    this.vidhansabhaList = '';
                }
            });
        },
        getStatesFilter(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getDistrictFilter(ev) {
            this.ApiService.getdistrict(ev).then((items) => {
                if (items.success === true) {
                    this.districtList = items.data;
                } else {
                    this.districtList = '';
                }
            });
        },
        getLokhsabhaFilter(ev) {
            this.ApiService.getloksabha(ev).then((items) => {
                if (items.success === true) {
                    this.loksabhaList = items.data;
                } else {
                    this.loksabhaList = '';
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
